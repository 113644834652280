import {
	login,
	mobileLogin,
	logout,
	getInfo,
	refreshToken
} from '@/api/member/login'
import {
	getToken,
	setToken,
	setExpiresIn,
	removeToken
} from '@/utils/auth'
import {
	getUnreadCount
} from "@/api/member/message";

const user = {
	state: {
		token: getToken(),
		userId: null,
		name: '',
		avatar: '',
		roles: [],
		permissions: [],
		attachinfo: {},
		messageUnread: 0
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_EXPIRES_IN: (state, time) => {
			state.expires_in = time
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_USER: (state, user) => {
			state.user = user
		},
		SET_USER_ID: (state, userId) => {
			state.userId = userId
		},
		SET_ATTACH_INFO: (state, attachinfo) => {
			state.attachinfo = attachinfo
		},
		SET_MESSAGE_UNREAD: (state, messageUnread) => {
			state.messageUnread = messageUnread
		}
	},

	actions: {
		// 登录
		Login({
			commit
		}, userInfo) {
			const username = userInfo.username.trim()
			const password = userInfo.password
			const code = userInfo.code
			const uuid = userInfo.uuid
			return new Promise((resolve, reject) => {
				login(username, password, code, uuid).then(res => {
					let data = res.data
					console.log('登录成功:', data);
					setToken(data.access_token)
					commit('SET_TOKEN', data.access_token)
					setExpiresIn(data.expires_in)
					commit('SET_EXPIRES_IN', data.expires_in)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 获取用户信息
		GetInfo({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getInfo().then(res => {
					console.log('用户信息：', res);
					const user = res.user
					const attachinfo = res.attachinfo
					const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : user.avatar;
					if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
						commit('SET_ROLES', res.roles)
						commit('SET_PERMISSIONS', res.permissions)
					} else {
						commit('SET_ROLES', ['ROLE_DEFAULT'])
					}
					commit('SET_NAME', user.userName)
					commit('SET_AVATAR', avatar)
					commit('SET_USER', user)
					commit('SET_USER_ID', user.userId)
					commit('SET_ATTACH_INFO', attachinfo)
					commit('SET_MESSAGE_UNREAD', res.messageUnread)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},

		//获取站内信未读数量
		getUnreadCount({
			commit
		}, userId) {
			return new Promise(resolve => {
				// 向后端请求路由数据
				getUnreadCount(userId).then(res => {
					let count = res.data;
					commit('SET_MESSAGE_UNREAD', count)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 刷新token
		RefreshToken({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				refreshToken(state.token).then(res => {
					setExpiresIn(res.data)
					commit('SET_EXPIRES_IN', res.data)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 退出系统
		LogOut({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				logout(state.token).then(() => {
					commit('SET_TOKEN', '')
					commit('SET_ROLES', [])
					commit('SET_PERMISSIONS', [])
					commit('SET_NAME', '')
					commit('SET_AVATAR', '')
					commit('SET_USER', {})
					commit('SET_USER_ID', null)
					commit('SET_ATTACH_INFO', {})
					removeToken()
					resolve()
					sessionStorage.clear();
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 前端 登出
		FedLogOut({
			commit
		}) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '')
				removeToken()
				resolve()
			})
		}
	}
}

export default user