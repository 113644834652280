module.exports = {
  /**
   * 是否显示动态标题
   */
  dynamicTitle: false,
	isProd: false, //是否是正式系统，测试：false，正式：true

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
	ssoLoginFuccessForward: '/member',//ilab单点登录成功跳转地址
	ssoLoginFailForward: 'https://www.ilab-x.com',//ilab单点登录失败跳转地址
	
	webSocketAddress: 'ws://192.168.2.237:9205' ,//本地测试地址
	//webSocketAddress: 'ws://1.192.121.238:9205' //测试服务器地址
	//webSocketAddress: 'ws://10.140.104.86:9205' //正式服务器地址
}
