import router from "@/router";

export function navClick(item) {
  let itemcode = item.id;
  console.log("当前栏目编号：", itemcode);
  let linkurl = item.linkurl;
  let itemtype = item.itemtype; //栏目类型  0:新闻栏目  1:非新闻栏目  2:介绍类
  console.log("linkurl:" + linkurl, "itemtype：" + itemtype);
  if (itemtype == 0) {
    //0:新闻栏目
    if (linkurl != null && linkurl != undefined && linkurl != "undefined" && linkurl != "") {
      if (linkurl.startsWith("http")) {
        //window.location.href = linkurl;
        window.open(linkurl);
      } else {
        if (linkurl.indexOf("?itemcode") > 0) {
          router.push(linkurl);
        } else {
          router.push({
            path: "/newsList",
            query: {
              itemcode: itemcode,
            },
          });
        }
      }
    } else {
      router.push({
        path: "/newsList",
        query: {
          itemcode: itemcode,
        },
      });
    }
  } else if (itemtype == 1) {
    //1:非新闻栏目
    if (linkurl != null && linkurl != undefined && linkurl != "undefined" && linkurl != "") {
      if (linkurl.startsWith("http")) {
        //window.location.href = linkurl;
        window.open(linkurl);
      } else {
        if (linkurl.indexOf("?itemcode") > 0) {
          router.push(linkurl);
        } else {
          router.push({
            path: linkurl,
            query: {
              itemcode: itemcode,
            },
          });
        }
      }
    } else {
      router.push({
        path: "/newsList",
        query: {
          itemcode: itemcode,
        },
      });
    }
  } else if (itemtype == 2) {
    //2:介绍类
    if (linkurl != null && linkurl != undefined && linkurl != "undefined" && linkurl != "") {
      if (linkurl.startsWith("http")) {
        //window.location.href = linkurl;
        window.open(linkurl);
      } else {
        if (linkurl.indexOf("?itemcode") > 0) {
          router.push(linkurl);
        } else {
          router.push({
            path: "/newsIntro",
            query: {
              itemcode: itemcode,
            },
          });
        }
      }
    } else {
      router.push({
        path: "/newsIntro",
        query: {
          itemcode: itemcode,
        },
      });
    }
  }
}

export function jumpHome() {
  router.push("/");
}

// 文章详情跳转
export function jumpArticleDetail(item) {
  router.push({
    path: `/newsDetail`,
    query: {
      id: item.id,
      itemcode: item.newsitemid,
    },
  });
}
// 跳转更多
export function jumpMoreNews(itemcode) {
  router.push({
    path: "newsList",
    query: {
      itemcode: itemcode,
    },
  });
}
// 二级网站
// 详情跳转
// query obj
// {
// 		memberUserId,
// 		subStationId
// 		itemcode,
//
// 	  },
export function goLev2Detail(id,queryObj) {
  router.push({
    path: `/myDetail`,
    query: {
      id,
	  ...queryObj
    },
  });
}
// 跳转更多
// query obj
// {
// 		memberUserId,
// 		subStationId
// 		itemcode,
//
// 	  },
export function goLev2MoreNews(queryObj,subStationId) {
  this.$router.push({
    path: "/myList",
    query: {
	  ...queryObj,
    subStationId,
    },
  });
}
