import router from './router'
import store from './store'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from '@/utils/auth'
import {validateNull} from "./utils/validate";

NProgress.configure({
  showSpinner: false
})

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  // console.log(1,getToken());
  NProgress.start()
  to.meta.title && store.dispatch('webSettings/setTitle', to.meta.title)
  if (getToken()) {
    console.log(2);
    /* has token*/
    if (to.path === '/login') {
      // 
      Message({
        showClose: true,
        message: '您已经登录了',
        type: 'warning',
      });
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      if (validateNull(store.getters.userId)) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          store.dispatch('GenerateRoutes').then(accessRoutes => {
          	// 根据roles权限生成可访问的路由表
          	router.addRoutes(accessRoutes) // 动态添加可访问路由表
          	next({
          		...to,
          		replace: true
          	}) // hack方法 确保addRoutes已完成
          })
          next({
            ...to,
            replace: true
          })
        }).catch(err => {
          store.dispatch('LogOut').then(() => {
            Message.error(err)
            next({
              path: '/'
            })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // console.log(5);
    // 没有token
    if (!to.meta.auth || whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // console.log(6);
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
