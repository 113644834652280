import request from '@/utils/request'

// 查询会员站内信列表
export function listMessage(query) {
  return request({
    url: '/ims-api/member/center/message/list',
    method: 'get',
    params: query
  })
}

// 查询会员站内信详细
export function getMessage(id) {
  return request({
    url: '/ims-api/member/center/message/' + id,
    method: 'get'
  })
}

// 新增会员站内信
export function addMessage(data) {
  return request({
    url: '/ims-api/member/center/message',
    method: 'post',
    data: data
  })
}

// 修改会员站内信
export function updateMessage(data) {
  return request({
    url: '/ims-api/member/center/message',
    method: 'put',
    data: data
  })
}

// 删除会员站内信
export function delMessage(id) {
  return request({
    url: '/ims-api/member/center/message/' + id,
    method: 'delete'
  })
}

// 导出会员站内信
export function exportMessage(query) {
  return request({
    url: '/ims-api/member/center/message/export',
    method: 'get',
    params: query
  })
}

// 会员站内信设为已读
export function receiveChangeStatus(data) {
  return request({
    url: '/ims-api/member/center/message/receiveChangeStatus',
    method: 'post',
    data: data
  })
}

// 会员删除站内信
export function receiveDeleteMessage(data) {
  return request({
    url: '/ims-api/member/center/message/receiveDeleteMessage',
    method: 'post',
    data: data
  })
}

/**
 * 发送站内信
 * @param {Object} data
 * sendUserId:当前登录会员编号
 * receiveUserId:接收人会员编号
 * content:内容
 * uuid:验证码唯一标识
 * code:验证码
 */
export function sendMessage(data) {
  return request({
    url: '/ims-api/member/center/message/sendMessage',
    method: 'post',
    data: data
  })
}