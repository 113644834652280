import request from '@/utils/request'

// 根据code获取页面代码
export function selectFimPagecodeByCode(code) {
	return request({
		url: '/ims-api/front/fim/pagecode/selectFimPagecodeByCode/' + code,
		method: 'get',
		params: {}
	})
}
