import Cookies from 'js-cookie'

const TokenKey = 'Member-Token'

const ExpiresInKey = 'Member-Expires-In'

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
	return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
	return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
	return Cookies.remove(ExpiresInKey)
}

export function isLogin() {
	let token = getToken();
	let tokenAvailable = false;
	if (token == null || token == "" || token == undefined || token == 'undefined') {
		tokenAvailable = false;
	} else {
		tokenAvailable = true;
	}
	return tokenAvailable;
}