/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ['admin', 'editor']
	return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
	const reg =
		/^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
	return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
	const reg = /^[a-z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
	const reg = /^[A-Z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
	const reg = /^[A-Za-z]+$/
	return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
	const reg =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
	if (typeof str === 'string' || str instanceof String) {
		return true
	}
	return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}

/**
 * 判断是否为空
 */
export function validateNull(val) {
	if (typeof val == 'boolean') {
		return false;
	}
	if (typeof val == 'number') {
		return false;
	}
	if (val instanceof Array) {
		if (val.length == 0) return true;
	} else if (val instanceof Object) {
		if (JSON.stringify(val) === '{}') return true;
	} else {
		if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
		return false;
	}
	return false;
}

// 验证内容是否包含英文数字以及下划线
export function isPassword(rule, value, callback) {
	const reg = /^[_a-zA-Z0-9]+$/;
	if (value == '' || value == undefined || value == null) {
		callback();
	} else {
		if (!reg.test(value)) {
			callback(new Error('仅由英文字母，数字以及下划线组成'));
		} else {
			callback();
		}
	}
}

// 中文校验
export const validateContacts = (rule, value, callback) => {
	if (!value) {
		return callback(new Error('请输入中文'))
	}
	if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
		callback(new Error('请输入中文'))
	} else {
		callback()
	}
}

export const validatePhone = (rule, value, callback) => {
	const phoneReg = /^1[34578]\d{9}$$/;
	if (!value) {
		return callback(new Error("手机号码不能为空"));
	}
	setTimeout(() => {
		if (!Number.isInteger(+value)) {
			callback(new Error("请输入数字值"));
		} else {
			if (phoneReg.test(value)) {
				callback();
			} else {
				callback(new Error("电话号码格式不正确"));
			}
		}
	}, 100);
};

// 纯数字校验
export const validateNumber = (rule, value, callback) => {
  let numberReg = /^(\-|\+)?\d+(\.\d+)?$/
  if (value!=null && value !== '') {
    if (!numberReg.test(value)) {
      callback(new Error('请输入数字'))
    } else {
      callback()
    }
  }else{
    callback()
  }
}

// 两位小数验证
export const validateValidity = (rule, value, callback) => {
  let reg = /(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:.\d{1,2})?$)/;
  if (value!=null && value !== '') {
    if (!reg.test(value)) {
      callback(new Error('最多两位小数！！！'));
    } else {
      callback();
    }
  }else{
    callback()
  }
};

// 四位小数验证
export const validateFourDecimalPlaces = (rule, value, callback) => {
  let reg = /(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:.\d{1,4})?$)/;
  if (value!=null && value !== '') {
    if (!reg.test(value)) {
      callback(new Error('最多四位小数！！！'));
    } else {
      callback();
    }
  }else{
    callback()
  }
};
