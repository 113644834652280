<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  metaInfo() {
    return {
      title: this.$store.state.webSettings.dynamicTitle && this.$store.state.webSettings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  },
  created() {
		this.$store.dispatch("getFileConfig");
		
		if (sessionStorage.getItem('store')) {
			this.$store.replaceState({
				...this.$store.state,
				...JSON.parse(sessionStorage.getItem('store'))
			})
		}
		
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>

<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
  font-size: 12px;
}

#app {
  background-image: url(./assets/images/bg_all.png);
  background-repeat: repeat-y;
}
</style>
