import defaultSettings from '@/webSettings'

const { dynamicTitle, isProd, webSocketAddress, ssoLoginFuccessForward, ssoLoginFailForward } = defaultSettings

const state = {
  title: '',
  dynamicTitle: dynamicTitle,
	isProd: isProd,
	webSocketAddress: webSocketAddress,
	ssoLoginFuccessForward: ssoLoginFuccessForward,
	ssoLoginFailForward: ssoLoginFailForward
}
const mutations = {
	
}

const actions = {
  // 设置网页标题
  setTitle({ commit }, title) {
    state.title = title
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

