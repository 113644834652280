import request from '@/utils/request'

// 账号登录方法
export function login(username, password, code, uuid) {
  return request({
    url: '/ims-api/member/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: { username, password, code, uuid }
  })
}

// 手机验证码登录方法
export function mobileLogin(username, code, uuid) {
  return request({
    url: '/ims-api/member/mobile/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: { username, code, uuid }
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/ims-api/member/registerComplex',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 刷新方法
export function refreshToken() {
  return request({
    url: '/ims-api/ylsoftrz/refresh',
    method: 'post'
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/ims-api/member/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/ims-api/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/ims-api/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 找会密码
export function retrievePassword(data) {
  return request({
    url: '/ims-api/member/retrievePassword',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
