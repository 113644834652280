import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import webSettings from './modules/webSettings'
import ims from './modules/ims'
import fileConfig from './modules/fileConfig'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		app,
		user,
		permission,
		webSettings,
		ims,
		fileConfig
	},
	getters
})

export default store
