<template>
	<!-- 底部start -->
	<div class="footer">
		<div class="footer_menu">
			<a @click="jumpHome"> 网站首页</a> 丨 
			<a @click="navClick(item)" v-for="(item,index) in navList" :key="index">{{item.name}} 丨 </a>
		</div>
		<div class="footer_copy" v-html="footerInfo.content">

		</div>
	</div>
	<!-- 底部end -->
</template>

<script>
	import {
		selectFimPagecodeByCode
	} from '@/api/ims/pagecode';
	import {
		selectTree
	} from '@/api/ims/newsitem';
	export default {
		name: "Footer",
		data() {
			return {
				navList: [], //栏目
				footerInfo: {}, // 底部footer信息,
			}
		},
		created() {
			this.selectTree();
			this.getFooterByCode();
		},
		methods: {
			selectTree() {
				selectTree(1).then(res => {
					this.navList = res.data;
					console.log('导航栏目树：', this.navList);
				})
			},
			getFooterByCode() {
				selectFimPagecodeByCode('copyright').then(response => {
					this.footerInfo = response.data;
					console.log('底部版权:', this.footerInfo);
				})
			},
		},
	}
</script>

<style scoped lang="scss">

</style>