import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../public/font/iconfont.css'

// 登陆、注册头部样式
import '../public/css/header.css'
// 引入页面通用样式：一级头部、底部、面包屑样式
import '../public/css/main.css'

// import '@/theme/global.css'
Vue.use(ElementUI);

import Meta from 'vue-meta';

Vue.use(Meta)

import plugins from './plugins' // plugins

import {
  getDicts
} from "@/api/system/dict/data";
import {
  getConfigKey
} from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
	download
} from "@/utils/zdthink";

import permission from './directive/permission'
import './permission' // permission control
// 分页组件
import Pagination from "@/components/Pagination";
// 字典数据组件
import DictTag from '@/components/DictTag';
import DictData from '@/components/DictData';
import RightToolbar from "@/components/RightToolbar"
import moment from 'moment';
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
import "./utils/directives.js"

//pdf
// 引入环图
import VeRing from 'v-charts/lib/ring.common'

Vue.component(VeRing.name, VeRing)

import scroll from 'vue-seamless-scroll'

Vue.use(scroll)

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('ImagePreview', ImagePreview)
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
moment.locale('zh-cn');
Vue.prototype.$moment = moment;


import { navClick,jumpHome,jumpArticleDetail,jumpMoreNews,goLev2Detail ,goLev2MoreNews} from "@/utils/common";
Vue.prototype.navClick = navClick
Vue.prototype.jumpHome = jumpHome
Vue.prototype.jumpArticleDetail = jumpArticleDetail
Vue.prototype.jumpMoreNews = jumpMoreNews
Vue.prototype.goLev2Detail = goLev2Detail
Vue.prototype.goLev2MoreNews = goLev2MoreNews

Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.use(plugins)
DictData.install()
Vue.use(permission)

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

Vue.filter('formatNumber', (number) => {
  if (!number) { //在这里进行一次传递数据判断.如果传递进来的为空值,返回其空字符串.解决其问题
    return '0.00';
  }
  return Number(number).toFixed(2);
})

import './assets/icons' // icon
import './../public/css/list.css'

Vue.config.productionTip = false

store.dispatch('getTitle');
store.dispatch('getDescription');
store.dispatch('getKeywords');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
