import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/404",
    component: () => import("@/views/error/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error/401"),
    hidden: true,
  },
  {
    path: "/sso",
    component: () => import("@/views/member/ssoLogin"),
    hidden: true,
  },
  {
    path: "/login",
    component: () => import("@/views/member/login"),
    hidden: true,
  },
  {
    path: "/register",
    component: () => import("@/views/member/register"),
    hidden: true,
  },
  {
    path: "/retrievePassword",
    component: () => import("@/views/member/retrievePassword"),
    hidden: true,
  },
  {
    path: "",
    component: () => import("@/layout/Layout"),
    redirect: "/",
    hidden: true,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          auth: false,
        },
      },
      {
        path: "/spotSearch",
        name: "spotSearch",
        component: () => import("@/views/spotSearch"),
        meta: {
          title: "现货搜索",
          auth: false,
        },
      },
      {
        path: "/spotSearchDetail",
        name: "spotSearchDetail",
        component: () => import("@/views/spotSearch/spotSearchDetail"),
        meta: {
          title: "现货搜索详情",
          auth: false,
         
        },
      },
      {
        path: "/businessYellowPages",
        name: "businessYellowPages",
        component: () => import("@/views/businessYellowPages"),
        meta: {
          title: "商家黄页",
          auth: false,
        },
      },
      {
        path: "/supplyHall",
        name: "supplyHall",
        component: () => import("@/views/supplyHall"),
        meta: {
          title: "供应大厅",
          auth: false,
        },
      },
      {
        path: "/supplyDetail",
        name: "supplyDetail",
        component: () => import("@/views/supplyHall/supplyDetail"),
        meta: {
          title: "供应详情",
          auth: false,
       
        },
      },
      {
        path: "/procurementHall",
        name: "procurementHall",
        component: () => import("@/views/procurementHall"),
        meta: {
          title: "采购大厅",
          auth: false,
        },
      },
      {
        path: "/procurementDetail",
        name: "procurementDetail",
        component: () => import("@/views/procurementHall/procurementDetail"),
        meta: {
          title: "采购详情",
          auth: false,
         
        },
      },
      {
        path: "/marketDynamics",
        name: "marketDynamics",
        component: () => import("@/views/marketDynamics"),
        meta: {
          title: "行情动态",
          auth: false,
        },
      },
      {
        path: "/customizedMerchants",
        name: "customizedMerchants",
        component: () => import("@/views/customizedMerchants"),
        meta: {
          title: "定制商家",
          auth: false,
        },
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs"),
        meta: {
          title: "关于我们",
          auth: false,
        },
      },
      {
        path: "/newsList",
        name: "newsList",
        component: () => import("@/views/news/list"),
        meta: {
          title: "文章列表",
          auth: false,
        },
      },
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () => import("@/views/news/detail"),
        props: true,
        meta: {
          title: "新闻详情",
          auth: false,
        },
      },
      {
        path: "newsIntro",
        name: "newsIntro",
        component: () => import("@/views/news/intro"),
        props: true,
        meta: {
          title: "新闻介绍",
          auth: false,
        },
      }, 
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/search"),
        props: true,
        meta: {
          title: "搜索列表",
          auth: false,
        },
      },   
    ],
  },
  {
    path: "/yellowPagesDetail",
    component: () => import("@/views/businessYellowPages/yellowPagesDetail/layout/Layout"),
    redirect: "/",
    hidden: true,
    children: [
      {
        path: "/myHome",
        name: "myHome",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myHome"),
        meta: {
          title: "首页",
          auth: false,
        },
      },,
      {
        path: "/myResources",
        name: "myResources",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myResources"),
        meta: {
          title: "我的资源",
          auth: false,
        },
      },
      {
        path: "/myResourcesDetail",
        name: "myResourcesDetail",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myResourcesDetail"),
        meta: {
          title: "资源详情",
          auth: false,
        },
      },
      {
        path: "/mySupply",
        name: "mySupply",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/mySupply"),
        meta: {
          title: "我的供应",
          auth: false,
        },
      },
      {
        path: "/mySupplyDetail",
        name: "mySupplyDetail",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/mySupplyDetail"),
        meta: {
          title: "供应详情",
          auth: false,
        },
      },
      {
        path: "/myProcurement",
        name: "myProcurement",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myProcurement"),
        meta: {
          title: "我的采购",
          auth: false,
        },
      },
      {
        path: "/myProcurementDetail",
        name: "myProcurementDetail",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myProcurementDetail"),
        meta: {
          title: "采购详情",
          auth: false,
        },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/contactUs"),
        meta: {
          title: "联系我们",
          auth: false,
        },
      },
      {
        path: "/myList",
        name: "myList",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myNews/myList"),
        meta: {
          title: "列表",
          auth: false,
        },
      },
      {
        path: "/myDetail",
        name: "myDetail",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myNews/myDetail"),
        props: true,
        meta: {
          title: "详情",
          auth: false,
        },
      },
      {
        path: "/myIntro",
        name: "myIntro",
        component: () => import("@/views/businessYellowPages/yellowPagesDetail/myNews/myIntro"),
        props: true,
        meta: {
          title: "介绍",
          auth: false,
        },
      },
      
    ],
  },
  {
    path: "/member/center",
    component: () => import("@/views/member/layout/Layout"),
    redirect: "/",
    hidden: true,
    children: [
      {
        path: "/",
        name: "Member",
        component: () => import("@/views/member"),
        meta: {
          title: "个人中心",
          auth: true,
        },
      },
    ],
  },
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes,
  // 路由跳转后 新页面从最顶部显示
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
