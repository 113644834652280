import {
	getConfigKey
} from '@/api/system/config';

const ims = {
	
	state: {
		title: '',
		description: '',
		keywords: ''
	},
	getters: {
		getTitle: (state) => {
		  return state.title;
		}
	},
	mutations: {
		setTitle: (state, obj) => {
		  state.title = obj;
		},
		setDescription: (state, obj) => {
		  state.description = obj;
		},
		setKeywords: (state, obj) => {
		  state.keywords = obj;
		},
	},
	actions: {
		getTitle({state,commit}) {
			if(state.title==null||state.title==''){
				getConfigKey("web.title").then((response) => {
				  let title = response.msg;
				  console.log('title:', title);
					document.title = title;
					commit('setTitle', title);
				});
			}
		},
		getDescription({state,commit}) {
			if(state.description==null||state.description==''){
				getConfigKey("web.description").then((response) => {
					let description = response.msg;
					console.log('description:', description);
					document.querySelector('meta[name="description"]').setAttribute('content', description);
					commit('setDescription', description);
				});
			}
		},
		getKeywords({state,commit}) {
			if(state.keywords==null||state.keywords==''){
				getConfigKey("web.keywords").then((response) => {
					let keywords = response.msg;
					console.log('keywords:', keywords);
					document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
					commit('setKeywords', keywords);
				});
			}
		}
	}

};
export default ims
