import request from '@/utils/request'

// 栏目树
export function selectTree(pid) {
	return request({
		url: '/ims-api/front/ims/newsitems/tree/' + pid,
		method: 'get',
		params: {}
	})
}

// 栏目详情
export function selectNewsitemsDetail(id) {
	return request({
		url: '/ims-api/front/ims/newsitems/detail/' + id,
		method: 'get',
		params: {}
	})
}

// 根据父栏目ID获取子栏目
export function selectNext(pid) {
	return request({
		url: '/ims-api/front/ims/newsitems/next/' + pid,
		method: 'get',
		params: {}
	})
}

// 根据父栏目ID获取子栏目
export function selectTreeByParams(params) {
	return request({
		url: '/ims-api/front/imsNewsitems/tree',
		method: 'get',
		params: {
			...params
		}
	})
}
