import { getFileConfig } from '@/api/system/config'

const fileConfig = {
  
  state: {
    webDomain: '',
    webUploadUrl: '',
    webFileUrl: ''
  },
	getters: {
		getWebFileUrl: (state) => {
		  return state.webFileUrl;
		},
		getWebUploadUrl: (state) => {
		  return state.webUploadUrl;
		},
		getWebFileUrl: (state) => {
		  return state.webFileUrl;
		}
	},
  mutations: {
    SET_WEBDOMAIL: (state, webDomain) => {
      state.webDomain = webDomain
    },
    SET_WEBUPLOADURL: (state, webUploadUrl) => {
      state.webUploadUrl = webUploadUrl
    },
    SET_WEBFILEURL: (state, webFileUrl) => {
      state.webFileUrl = webFileUrl
    },
  },
  actions: {
    getFileConfig({ commit }) {
      return new Promise(resolve => {
        getFileConfig().then(res => {
          let data = res.data;
          console.log('[文件上传参数]：', data);
          commit('SET_WEBDOMAIL', data.webDomain);
          commit('SET_WEBUPLOADURL', data.webUploadUrl);
          commit('SET_WEBFILEURL', data.webFileUrl);
        })
      })
    }
  }
}

export default fileConfig
