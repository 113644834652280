const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  user: (state) => state.user.user,
  userId: (state) => state.user.userId,
	messageUnread: (state) => state.user.messageUnread,
  attachinfo: (state) => state.user.attachinfo,
  experimentId: (state) => state.user.attachinfo.experimentId,
  experimenName: (state) => state.user.attachinfo.experimenName,
  memberLevel: (state) => state.user.attachinfo.memberLevel,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  topbarRouters: (state) => state.permission.topbarRouters,
  defaultRoutes: (state) => state.permission.defaultRoutes,
  sidebarRouters: (state) => state.permission.sidebarRouters,

  isProd: (state) => state.webSettings.isProd,
  webSocketAddress: (state) => state.webSettings.webSocketAddress,
  ssoLoginFuccessForward: (state) => state.webSettings.ssoLoginFuccessForward,
  ssoLoginFailForward: (state) => state.webSettings.ssoLoginFailForward,

  webDomain: (state) => state.fileConfig.webDomain,
  webUploadUrl: (state) => state.fileConfig.webUploadUrl,
  webFileUrl: (state) => state.fileConfig.webFileUrl,

  msgNum: (state) => state.msg.msgNum,
};
export default getters;
