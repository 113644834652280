<template>
	<div class="top_login">
		<!-- 头部登录start -->
		<div class="w1199 dbf">
			<div class="login_left">
				您好，欢迎访问云钢联门户官网！
				<span v-if="!loginStaus">请 <a href="javascript:void(0)" @click="toLogin">登录</a></span>
			</div>
			<div class="login_right">
				<router-link to="/" style="float: left">
					<img src="@/assets/images/gray-home.png" class="topMenuPic" />
					<span class="topMenu-span">首页</span>
					<i class="line"></i>
				</router-link>
				<div class="home-topfr fr" v-if="loginStaus">
					<label>
						<img src="@/assets/images/user.png" class="topMenuPic" />
						<span class="topMenu-span">欢迎您，<a>{{ user.nickName }}! </a></span>
					</label>
					<i class="line"></i>
					<label title="信息报送中心" style="cursor: pointer" @click="personalCenter()">
						<img src="@/assets/images/report-center.png" class="topMenuPic" />
						<span class="topMenu-span">用户中心</span></label>
					<i class="line"></i>
					<label title="未读信息" style="cursor: pointer" class="msg-card" @click="$router.push('/member/interaction/message')">
						<img src="@/assets/images/msg.svg" class="topMenuPic" />
						<el-badge :value="messageUnread" :max="99" class="item">
							<span class="topMenu-span"></span>
						</el-badge>
					</label>
					<i class="line"></i>
					<label style="cursor: pointer" @click="logOut()">
						<img src="@/assets/images/exit.png" class="topMenuPic" />
						<span class="topMenu-span">[退出] </span>
					</label>
				</div>
				<a href="javascript:void(0)" @click="$router.push('/register')" v-else>注册</a>
			</div>
		</div>
	</div>
	<!-- 头部登录end -->
</template>

<script>
	import {
		isLogin
	} from "@/utils/auth.js";
	import {
		mapGetters,
		mapActions
	} from "vuex";

	export default {
		name: "TopMenu",
		data() {
			return {
				loginStaus: false,
				
				webSocket: null,
				isConnect: false,
				heartCheck: {
					checkMsg: 'heartbeat',
					timeout: 50000,
					timeoutObj: null
				}
			};
		},
		computed: {
			...mapGetters(["userId", "user", "messageUnread"]),
		},
		mounted() {
			console.log('messageUnread',this.messageUnread)
			if (isLogin()) {
				this.loginStaus = true;
				this.initWebSocket();
			}
		},
		methods: {
			toLogin() {
				this.$router.push({
					path: "/login",
				});
			},
			personalCenter() {
				this.$router.push("/member/center");
			},
			logOut() {
				this.$confirm("是否退出登录?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$store.dispatch("LogOut").then(() => {
						this.loginStaus = false;
						this.$router.push("/");
					});
				});
			},
			initWebSocket() {
				const socketUrl = "ws://" + location.host + "/websocket/ws/message/unread/" + this.userId;
				console.log(socketUrl);
				if (this.socket != null) {
					this.webSocket.close();
					this.webSocket = null;
				}
				
				this.webSocket = new WebSocket(socketUrl);
				this.webSocket.onmessage = this.websocketonmessage;
				this.webSocket.onopen = this.websocketonopen;
				this.webSocket.onerror = this.websocketonerror;
				this.webSocket.onclose = this.websocketclose;
			},
			websocketonopen() {// 连接建立成功
				console.log("连接成功");
				this.isConnect=true;
				this.heartCheckStart();
			},
			websocketonerror() {// 连接建立失败重连
				setTimeout(() => {
					this.initWebSocket();
				}, 5000)
			},
			websocketonmessage(response) {
				console.log('推送的内容', response)
				let data = JSON.parse(response.data);
				if (data.code == '200') {
					if(data.msg=='000002'){
						this.$store.dispatch("getUnreadCount", this.userId).then((res) => {
							console.log('getUnreadCount res:', res);
						});
					}else if(data.msg=='000003'){
						console.log('heartbeat');
						this.heartCheckReset();
					}
				}
			},
			websocketclose(e) {// 关闭
				console.log("断开连接");
				this.isConnect=false;
			},
			reConnect(){
				console.log('尝试重新连接');
				//如果已经连上就不在重连了
				if(this.isConnect) return;
				rec&&clearTimeout(rec);
				// 延迟5秒重连  避免过多次过频繁请求重连
				rec=setTimeout(function(){
					createWebSocket();
				},5000);
			},
			heartCheckStart(){
				this.timeoutObj = setTimeout(() => {
					if(this.isConnect) this.webSocket.send(this.heartCheck.checkMsg);
				}, this.heartCheck.timeout);
			},
			heartCheckReset(){
				clearTimeout(this.timeoutObj);
				this.heartCheckStart();
			}
		}
	};
</script>

<style scoped lang="scss">
	.top_login {
		width: 100%;
		height: 36px;
		line-height: 36px;
		background-color: #fbfbfb;
		box-shadow: 0px 1px 0px 0px #e7e7e7;
	}

	.top_login .login_left {
		line-height: 36px;
	}

	.top_login .login_left a {
		color: #0f92eb;
	}

	.top_login .login_right {
		line-height: 36px;
	}

	.top_login .login_right a {
		color: #0f92eb;
	}

	.top_login .login_right .topMenuPic {
		width: 18px;
		float: left;
		margin-top: 8px;
	}

	.top_login .login_right .topMenu-span {
		float: left;
		margin-left: 5px;
		color: #666;
		font-weight: normal;
	}

	.top_login .login_right .topMenu-span:hover {
		color: #0f92eb;
	}

	.line {
		width: 1px;
		height: 16px;
		background: #e9e6e6;
		margin: 10px;
		display: inline-block;
	}

	.home-topfr label {
		display: inline-block;
	}

	// 徽标样式
	.el-badge {
		vertical-align: baseline;
		margin-right: 20px;
	}

	.msg-card .topMenu-span {
		display: inline-block;
		height: 36px;
		line-height: 36px;
	}

	::v-deep .el-badge__content.is-fixed {
		top: 8px;
		right: 8px;
	}
</style>