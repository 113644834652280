<template>
	<div style="background-color: #F2F2F2;">
		<top-menu></top-menu>
		<div class="member-head">
			<div class="member-wrap cursor-p width1200" @click="jumpHome()">
				<img src="../../../assets/images/logo.svg" height="80" alt="">
			</div>
		</div>
		<div class="lw">
			<breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
		</div>
		

		<div class="lw member-center clearfix">
			<sidebar class="sidebar-container" />
			<router-view></router-view>
		</div>
	
		<member-footer></member-footer>
	</div>
</template>

<script>
	import './../../../../public/css/member.css'
	require('./../../../../public/font/iconfont')
	import '@/assets/styles/index.scss' // global css
	import '@/assets/styles/jeethink.scss' // jeethink css

	import TopMenu from '@/layout/TopMenu'
	import Breadcrumb from './components/Breadcrumb'
	import MemberFooter from '@/layout/Footer'
	import Sidebar from './components/Sidebar'

	export default {
		name: 'Layout',
		components: {
			Breadcrumb,
			TopMenu,
			Sidebar,
			MemberFooter
		},
		data() {
			return {
				title: this.$route.meta.title
			}
		}
	}
</script>

<style>
</style>